<template>
    <div class="Sale departmentGuaranteeAmount" v-loading="loading">
        <div class="header_Sale">
            <span class="el_left">生效时间</span>
            <div class="block el_left">
                <el-date-picker
                    v-model="departmentStaff.dept.effective_time"
                    type="datetime"
                    placeholder="选择日期时间"
                    align="right"
                    readonly
                    :picker-options="pickerOptions"
                    @change="preserveBasicInfo()"
                >
                </el-date-picker>
            </div>
            <el-button
                class="el_left"
                type="primary"
                v-if="isReadOnly == false"
                @click="release"
            >
                发布
            </el-button>
            <el-select
                class="el_left"
                v-model="historyID"
                placeholder="请选择"
            >
                <el-option
                    v-for="item in history"
                    :key="item.period"
                    :label="item.period"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </div>
        <div class="quota">
            <div class="totalLimit el_left">
                <i class="iconfont iconzhangdanBill"></i>
                <p>{{ departmentStaff.dept.dept_name }}销售部销售总信用额度</p>
                <div class="el_right">
                    <strong>{{ departmentStaff.dept.total_amount }}</strong>
                    <span style="line-height:0.6rem;">万</span>
                </div>
            </div>
            <div class="surplusQuota el_right">
                <i class="iconfont iconyue"></i>
                <p>{{ departmentStaff.dept.dept_name }}销售部剩余信用额度</p>
                <div class="el_right">
                    <strong>{{ departmentStaff.dept.surplus_amount }}</strong>
                    <span>万</span>
                </div>
            </div>
        </div>
        <ul class="quotaDetails">
            <template v-for="(item,index) in departmentStaff.persons">
                <li class="main" :key="index">
                    <div class="el_left info">
                        <p class="iconfont iconminsuzhuguanli">
                            {{ item.user_name }}
                        </p>
                        <span :title="item.user_post">{{ item.user_post }}</span>
                    </div>
                    <div class="el_right limit">
                        <input
                            type="text"
                            v-model="item.adjust_total_amount"
                            :readonly="isReadOnly"
                            @change="modifySalespersonInfo(item)"
                        >
                        <i>万</i>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import './css/saleStyle.styl';

export default {
    // 部门内担保额度设置
    name: 'department-guarantee-amount',
    components: {},
    props: {},
    data() {
        return {
            // 页面加载状态
            loading: false,
            // 生效时间
            validTimeValue: '',
            // 期数数据集合
            history: [],
            // 成成新期数按钮loading状态
            buttonLoading: false,
            // 当前打开期数id
            historyID: '',
            // 是否只读
            isReadOnly: false,
            // 当前期数发布状态 0未发布 1已发布
            status: 0,
            // 时间控件快捷选项
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    },
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    },
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    },
                }],
            },
            // 拉取部门及部门下人员数据
            departmentStaff: {
                dept: {
                    id: '',
                    period: '',
                    period_id: '',
                    dept_id: '',
                    dept_name: '',
                    adjust_amount: 0,
                    total_amount: 0,
                    surplus_amount: 0,
                    effective_time: new Date(),
                    status: 0,
                    sort_num: 0,
                },
                persons: [],
            },
        };
    },
    watch: {
        historyID() {
            this.getDepartmentStaff();
        },
        status(neeVal) {
            if (neeVal === 0) {
                this.isReadOnly = false;
            } else {
                this.isReadOnly = true;
            }
        },
    },
    computed: {},
    created() {
        this.loading = true;
        // 拉取期数数据
        this.getPeriodsNumber();
    },
    mounted() {},
    methods: {
        // 拉取期数数据
        getPeriodsNumber() {
            this.$axios
                .get('/interfaceApi/sale/sale_guarantee_amount/publish_periods')
                .then(res => {
                    if (res.length > 0) {
                        this.history = res;
                        this.historyID = res[0].id;
                    }
                    // 拉取部门及部门人员数据
                    this.getDepartmentStaff();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 拉取部门及部门人员数据
        getDepartmentStaff() {
            this.$axios
                .get(`/interfaceApi/sale/sale_guarantee_amount/dept/${this.historyID}`)
                .then(res => {
                    if (res) {
                        this.departmentStaff = res;
                        this.status = res.dept.status;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 修改销售人员信息
        modifySalespersonInfo(data) {
            this.loading = true;
            this.saveChanges(data);
        },
        // 保存修改
        saveChanges(saveData) {
            this.$axios
                .post('/interfaceApi/sale/sale_guarantee_amount/person/update', saveData)
                .then(() => {
                    this.loading = false;
                    this.getDepartmentStaff();
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.getDepartmentStaff();
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 发布
        release() {
            this.loading = true;
            this.$axios
                .post(`/interfaceApi/sale/sale_guarantee_amount/dept/publish?period_id=${this.departmentStaff.dept.period_id}`)
                .then(() => {
                    this.loading = false;
                    this.getDepartmentStaff();
                    this.$message({
                        message: '发布成功！',
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择日期更新基础信息
        preserveBasicInfo() {
        },
    },
};
</script>
<style lang="stylus" scoped>
</style>